import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Box = styled.div`
  background: #000;
  color: #fff !important;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  position: relative;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
`
const PortfolioMeta = props => {
  if (!props.tech || !props.role) {
    return null
  }

  return (
    <Box>
      {props.role ? (
        <p>
          <b style={{ fontWeight: 600 }}>My roles: </b> {props.role}
        </p>
      ) : null}
      {props.tech ? (
        <p>
          <b style={{ fontWeight: 600 }}>Tech: </b> {props.tech}
        </p>
      ) : null}
    </Box>
  )
}

PortfolioMeta.propTypes = {
  role: PropTypes.string,
  tech: PropTypes.string,
}

export default PortfolioMeta
