import React from "react"
import PropTypes from "prop-types"
import Container from "./Container"

const HeroBanner = props => {
  return (
    <section className="section is-medium">
      <Container>
        <h1 className="title">{props.title}</h1>
        {props.subTitle ? <h2 className="subtitle">{props.subTitle}</h2> : null}
      </Container>
    </section>
  )
}

HeroBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
}

export default HeroBanner
