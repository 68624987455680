import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Container from "./Container"

const CTA = props => {
  return (
    <section className="section is-medium px-0">
      <Container style={{ textAlign: "center" }}>
        <h2 className="title mb-5 is-size-4-mobile">
          I'd love to discover how I can apply{" "}
          <br className="is-hidden-mobile" />
          my expertise to your project.
        </h2>
        <div
          className="buttons are-medium"
          style={{ justifyContent: "center" }}
        >
          {props.works ? (
            <Link to="/portfolio" className="button">
              My works
            </Link>
          ) : null}
          <Link to="/contact" className="button is-black">
            Get a Free Quote
          </Link>
        </div>
      </Container>
    </section>
  )
}

CTA.defaultProps = {
  works: true,
}

CTA.propTypes = {
  works: PropTypes.bool,
}

export default CTA
