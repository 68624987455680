import React from "react"
import { graphql } from "gatsby"
import Container from "../components/Container"
import HeroBanner from "../components/HeroBanner"
import CTA from "../components/CTA"
import Pagination from "../components/Pagination"
import PortfolioMeta from "../components/PortfolioMeta"
import SEO from "../components/SEO"

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <div className="blog-post-container">
      <SEO title={frontmatter.title} />

      <HeroBanner title={frontmatter.title} />
      <div className="portfolio mb-6">
        <Container>
          <PortfolioMeta tech={frontmatter.tech} role={frontmatter.role} />
          <div
            className="portfolio-content content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Container>
      </div>
      <Pagination next={pageContext.next} prev={pageContext.previous} />
      <CTA works={false} />
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        tech
        role
      }
    }
  }
`
